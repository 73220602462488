import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";

const Header = () => {
  return (
    <>
      <div className="mobile-top-header">
        <Link to="/" className="logo-link">
          LTCM
        </Link>
      </div>
      <header className="header">
        <div className="header-container">
          <div className="logo">
            <Link to="/" className="logo-link">
              <span className="logo-full">Long Term Capitol Management</span>
            </Link>
          </div>
          <nav>
            <ul className="nav-links">
              <li><Link to="/">Home</Link></li>
              <li><Link to="/laws/118">Laws</Link></li>
              <li><Link to="/bills">Bills</Link></li>
              <li><Link to="/members">Members</Link></li>
              <li><Link to="/about">About</Link></li>
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
