import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import LoadingSpinner from './components/LoadingSpinner';

const Members = ({ apiKey }) => {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [clickLoading, setClickLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();
  const LIMIT = 20;

  const sortMembers = (members) => {
    return members.sort((a, b) => {
      // First sort by chamber (Senate first)
      const chamberA = a.terms?.item[0]?.chamber;
      const chamberB = b.terms?.item[0]?.chamber;
      if (chamberA === "Senate" && chamberB !== "Senate") return -1;
      if (chamberA !== "Senate" && chamberB === "Senate") return 1;
      
      // Then sort by state
      if (a.state !== b.state) return a.state.localeCompare(b.state);
      
      // Finally sort by name
      return a.name.localeCompare(b.name);
    });
  };

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const response = await axios.get(
          `https://api.congress.gov/v3/member/congress/118`,
          {
            params: {
              api_key: apiKey,
              format: 'json',
              offset: offset,
              limit: LIMIT
            }
          }
        );
        
        const newMembers = response.data.members;
        if (newMembers.length < LIMIT) {
          setHasMore(false);
        }
        
        const sortedMembers = sortMembers(newMembers);
        setMembers(prevMembers => {
          const allMembers = [...prevMembers, ...sortedMembers];
          return sortMembers(allMembers);
        });
        setLoading(false);
      } catch (error) {
        console.error('Error fetching members:', error);
        setError(error);
        setLoading(false);
      }
    };

    fetchMembers();
  }, [apiKey, offset]);

  // Memoize the scroll handler
  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop
      >= document.documentElement.offsetHeight - 100
      && !loading
      && hasMore
    ) {
      setOffset(prevOffset => prevOffset + LIMIT);
    }
  }, [loading, hasMore, LIMIT]);

  // Add scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const handleMemberClick = (bioguideId) => {
    setClickLoading(true);
    navigate(`/member/${bioguideId}`);
  };

  if (loading && members.length === 0) return <LoadingSpinner />;
  if (error) return <div>Error fetching data: {error.message}</div>;

  return (
    <div className="members-content">
      {clickLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {members.map((member) => (
            <div
              key={member.bioguideId}
              className="standard-member"
              onClick={() => handleMemberClick(member.bioguideId)}
            >
              <div className="member-info">
                <h2>{member.name}</h2>
                <p>
                  {member.terms.item[0].chamber} - {member.state}
                  {member.district && ` (District ${member.district})`}
                </p>
                <p className="member-party">{member.partyName}</p>
              </div>
              {member.depiction?.imageUrl && (
                <img
                  src={member.depiction.imageUrl}
                  alt={member.name}
                  className="member-image"
                  onError={(e) => {
                    e.target.style.display = 'none';
                  }}
                />
              )}
            </div>
          ))}
          {loading && members.length > 0 && <LoadingSpinner />}
          {!hasMore && members.length > 0 && (
            <div className="end-message">No more members to load</div>
          )}
        </>
      )}
    </div>
  );
};

export default Members;
