import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Members from "./Members";
import MemberDetail from "./MemberDetail";
import Bills from "./Bills";
import BillDetail from "./BillDetail";
import { LoadingProvider } from "./LoadingContext";
import Header from "./Header"; 
import EmailBox from "./EmailBox";
import "./App.css";
import Laws from "./Laws";
import About from "./About";
import AdSense from "./components/AdSense";
import Home from './components/Home';
import CommitteeDetail from './components/CommitteeDetail';

const App = () => {
  const apiKey = "NHdJjzbcGyGWRWiqihZ5SH6FZdChkW2fMVOBh5lT";

  return (
    <LoadingProvider>
      <Router>
        <div className="app-container">
          <Header />
          <div className="main-layout">
            <div className="ad-column left">
              <AdSense />
            </div>
            <div className="content">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/laws/:congress" element={<Laws />} />
                <Route path="/bills" element={<Bills apiKey={apiKey} />} />
                <Route path="/bills/:congress/:type/:number" element={<BillDetail />} />
                <Route path="/members" element={<Members apiKey={apiKey} />} />
                <Route path="/member/:bioguideId" element={<MemberDetail />} />
                <Route path="/about" element={<About />} />
                <Route path="/committee/:chamber/:systemCode" element={<CommitteeDetail />} />
              </Routes>
            </div>
            <div className="ad-column right">
              <AdSense />
            </div>
          </div>
        </div>
      </Router>
    </LoadingProvider>
  );
};

export default App;
