import React, { useEffect, useRef } from 'react';

const AdSense = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    let timeoutId;

    const initAd = () => {
      if (containerRef.current && containerRef.current.offsetWidth > 0) {
        try {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (e) {
          console.error('AdSense error:', e);
        }
      } else {
        // If container is not ready, try again in 100ms
        timeoutId = setTimeout(initAd, 100);
      }
    };

    // Initial attempt after a short delay
    timeoutId = setTimeout(initAd, 100);

    // Cleanup
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  return (
    <div 
      ref={containerRef}
      style={{ 
        width: '160px', 
        height: '600px',
        margin: '20px auto',
      }}
    >
      <ins
        className="adsbygoogle"
        style={{
          display: 'block',
          width: '160px',
          height: '600px',
        }}
        data-ad-client="ca-pub-8660242891087366"
        data-ad-slot="6066788935"
        data-ad-format="vertical"
        data-full-width-responsive="false"
      />
    </div>
  );
};

export default AdSense; 