import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import LoadingSpinner from './LoadingSpinner';
import './CommitteeDetail.css';
import DOMPurify from 'dompurify';

const CommitteeDetail = () => {
  const { chamber, systemCode } = useParams();
  const [committee, setCommittee] = useState(null);
  const [committeeDetails, setCommitteeDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [wikiSummary, setWikiSummary] = useState('');
  const [members, setMembers] = useState([]);
  const apiKey = 'NHdJjzbcGyGWRWiqihZ5SH6FZdChkW2fMVOBh5lT';
  const navigate = useNavigate();

  const fetchWikipediaData = async (committeeName) => {
    try {
      const searchResponse = await axios.get(
        `https://en.wikipedia.org/w/api.php?action=query&list=search&srsearch=${encodeURIComponent(committeeName)}&format=json&origin=*`
      );

      if (searchResponse.data.query.search.length > 0) {
        const pageId = searchResponse.data.query.search[0].pageid;

        const pageResponse = await axios.get(
          `https://en.wikipedia.org/w/api.php?action=query&pageids=${pageId}&prop=extracts&exintro=true&format=json&origin=*`
        );

        const page = pageResponse.data.query.pages[pageId];
        
        if (page.extract) {
          setWikiSummary(page.extract);
        }
      }
    } catch (error) {
      console.error('Error fetching Wikipedia data:', error);
    }
  };

  useEffect(() => {
    const fetchCommitteeDetails = async () => {
      try {
        // Get basic committee info
        const committeeRes = await axios.get(
          `https://api.congress.gov/v3/committee/118/${chamber}?api_key=${apiKey}`
        );

        const committeeData = committeeRes.data.committees.find(
          comm => comm.systemCode === systemCode
        );

        if (committeeData) {
          setCommittee(committeeData);
          await fetchWikipediaData(committeeData.name);
          
          // Get detailed committee info
          const detailsRes = await axios.get(
            `https://api.congress.gov/v3/committee/${chamber}/${systemCode}?api_key=${apiKey}`
          );
          setCommitteeDetails(detailsRes.data.committee);

          if (detailsRes.data.committee.members) {
            setMembers(detailsRes.data.committee.members);
          }
        } else {
          setError('Committee not found');
        }
        
        setLoading(false);
      } catch (error) {
        console.error('Error fetching committee details:', error);
        setError('Failed to load committee details');
        setLoading(false);
      }
    };

    fetchCommitteeDetails();
  }, [chamber, systemCode]);

  // In your component, add this helper function to sort and group members
  const organizeMembers = (members) => {
    const majority = members.filter(m => 
      (m.party === 'D' && chamber === 'senate') || 
      (m.party === 'R' && chamber === 'house')
    );
    
    const minority = members.filter(m => 
      (m.party === 'R' && chamber === 'senate') || 
      (m.party === 'D' && chamber === 'house')
    );

    return { majority, minority };
  };

  const handleSubcommitteeClick = (subcommittee) => {
    navigate(`/committee/${chamber}/${subcommittee.systemCode}`);
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <div className="error-message">{error}</div>;
  if (!committee) return <div>Committee not found</div>;

  return (
    <div className="committee-detail-container">
      <h1>{committee.name}</h1>
      
      {wikiSummary && (
        <section className="committee-overview">
          <div className="wiki-content">
            <div className="wiki-summary">
              <div 
                dangerouslySetInnerHTML={{ 
                  __html: DOMPurify.sanitize(wikiSummary) 
                }} 
              />
              <p className="wiki-attribution">
                Source: Wikipedia
              </p>
            </div>
          </div>
        </section>
      )}

      <section className="committee-info">
        <h2>Committee Information</h2>
        <div className="info-grid">
          <div className="info-item">
            <h3>Type</h3>
            <p>{committee.committeeTypeCode || 'Not specified'}</p>
          </div>
          <div className="info-item">
            <h3>Chamber</h3>
            <p>{committee.chamber}</p>
          </div>
          {committeeDetails?.isCurrent && (
            <div className="info-item">
              <h3>Status</h3>
              <p>Currently Active</p>
            </div>
          )}
        </div>
      </section>

      {members.length > 0 && (
        <section className="committee-members">
          <h2>Current Membership</h2>
          <div className="membership-layout">
            <div className="membership-column">
              <h3>Majority</h3>
              <ul className="member-list">
                {organizeMembers(members).majority.map((member, index) => (
                  <li key={index} className="member-item">
                    {member.name}, {member.state}
                    {member.role && member.role !== 'Member' && `, ${member.role}`}
                  </li>
                ))}
              </ul>
            </div>
            <div className="membership-column">
              <h3>Minority</h3>
              <ul className="member-list">
                {organizeMembers(members).minority.map((member, index) => (
                  <li key={index} className="member-item">
                    {member.name}, {member.state}
                    {member.role && member.role !== 'Member' && `, ${member.role}`}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      )}

      {committeeDetails?.history && (
        <section className="committee-history">
          <h2>Committee History</h2>
          {committeeDetails.history.map((item, index) => (
            <div key={index} className="history-item">
              <h3>{item.officialName}</h3>
              {item.startDate && (
                <p>Started: {new Date(item.startDate).toLocaleDateString()}</p>
              )}
              {item.endDate && (
                <p>Ended: {new Date(item.endDate).toLocaleDateString()}</p>
              )}
              {item.establishingAuthority && (
                <p>Established by: {item.establishingAuthority}</p>
              )}
            </div>
          ))}
        </section>
      )}

      {committee.subcommittees && committee.subcommittees.length > 0 && (
        <section className="subcommittees">
          <h2>Subcommittees</h2>
          <div className="subcommittees-grid">
            {committee.subcommittees.map((sub) => (
              <div 
                key={sub.systemCode} 
                className="subcommittee-card"
                onClick={() => handleSubcommitteeClick(sub)}
                style={{ cursor: 'pointer' }}
              >
                <h3>{sub.name}</h3>
                <p className="system-code">Code: {sub.systemCode}</p>
              </div>
            ))}
          </div>
        </section>
      )}

      {committeeDetails?.reports && (
        <section className="committee-stats">
          <h2>Committee Activity</h2>
          <div className="stats-grid">
            {committeeDetails.reports.count > 0 && (
              <div className="stat-item">
                <h3>Reports</h3>
                <p>{committeeDetails.reports.count} reports issued</p>
              </div>
            )}
            {committeeDetails.bills?.count > 0 && (
              <div className="stat-item">
                <h3>Bills</h3>
                <p>{committeeDetails.bills.count} bills handled</p>
              </div>
            )}
            {committeeDetails.nominations?.count > 0 && (
              <div className="stat-item">
                <h3>Nominations</h3>
                <p>{committeeDetails.nominations.count} nominations processed</p>
              </div>
            )}
          </div>
        </section>
      )}
    </div>
  );
};

export default CommitteeDetail; 