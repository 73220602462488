import React, { useState, useEffect, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import "./BillDetail.css";
import LoadingSpinner from './components/LoadingSpinner';

const BillDetail = () => {
  const apiKey = "NHdJjzbcGyGWRWiqihZ5SH6FZdChkW2fMVOBh5lT";
  const { congress, type, number } = useParams();
  const [bill, setBill] = useState(null);
  const [summaries, setSummaries] = useState([]);
  const [cosponsors, setCosponsors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [summariesError, setSummariesError] = useState(null);
  const [cosponsorsError, setCosponsorsError] = useState(null);
  const [showAllCosponsors, setShowAllCosponsors] = useState(false);
  const [billTextUrls, setBillTextUrls] = useState({ html: null, pdf: null });
  const maxVisibleCosponsors = 5;
  const [sponsorClickLoading, setSponsorClickLoading] = useState(false);
  const [cosponsorClickLoading, setCosponsorClickLoading] = useState(false);

  const fetchBillData = useCallback(async () => {
    if (!congress || !type || !number) {
      setError('Invalid bill parameters');
      return;
    }

    setLoading(true);
    setError(null);
    setSummariesError(null);
    setCosponsorsError(null);

    try {
      const billUrl = `https://api.congress.gov/v3/bill/${congress}/${type.toLowerCase()}/${number}`;
      const billResponse = await axios.get(billUrl, { params: { format: 'json', api_key: apiKey } });
      setBill(billResponse.data.bill);

      const summariesUrl = `https://api.congress.gov/v3/bill/${congress}/${type.toLowerCase()}/${number}/summaries`;
      const summariesResponse = await axios.get(summariesUrl, { params: { api_key: apiKey } });
      setSummaries(summariesResponse.data.summaries || []);

      const cosponsorsUrl = `https://api.congress.gov/v3/bill/${congress}/${type.toLowerCase()}/${number}/cosponsors`;
      const cosponsorsResponse = await axios.get(cosponsorsUrl, { params: { api_key: apiKey } });
      setCosponsors(cosponsorsResponse.data.cosponsors || []);

      // Fetch bill text versions
      const textVersionsUrl = `https://api.congress.gov/v3/bill/${congress}/${type.toLowerCase()}/${number}/text`;
      const textVersionsResponse = await axios.get(textVersionsUrl, { params: { api_key: apiKey } });
      const textVersions = textVersionsResponse.data.textVersions;

      if (textVersions && textVersions.length > 0) {
        // Get the most recent version
        const latestVersion = textVersions[0];
        const htmlUrl = latestVersion.formats.find(format => format.type === "Formatted Text")?.url;
        const pdfUrl = latestVersion.formats.find(format => format.type === "PDF")?.url;
        setBillTextUrls({ html: htmlUrl, pdf: pdfUrl });
      }
    } catch (error) {
      console.error('Error fetching bill data:', error);
      setError(error.response?.data?.error || 'Failed to fetch bill data');
      if (error.response?.status === 404) {
        setSummariesError('No summaries available for this bill.');
        setCosponsorsError('No cosponsors available for this bill.');
      }
    } finally {
      setLoading(false);
    }
  }, [congress, type, number, apiKey]);

  useEffect(() => {
    fetchBillData();
  }, [fetchBillData]);

  const handleSponsorClick = () => {
    setSponsorClickLoading(true);
  };

  const handleCosponsorClick = () => {
    setCosponsorClickLoading(true);
  };

  if (loading || sponsorClickLoading || cosponsorClickLoading) return <LoadingSpinner />;
  if (error) return <div>Error: {error}</div>;
  if (!bill) return <div>No bill data available.</div>;

  // Sort summaries by date in descending order (most recent first)
  const sortedSummaries = summaries.sort((a, b) => 
    new Date(b.actionDate) - new Date(a.actionDate)
  );

  // Function to format date
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  return (
    <div className="bill-detail">
      <h3 className="bill-title">{bill.type}.{bill.number} - {bill.title}</h3>
      
      <section className="bill-section">
        <h2>Introduced</h2>
        <p>{new Date(bill.introducedDate).toLocaleDateString()}</p>
      </section>

      <section className="bill-section">
        <h2>Latest Action</h2>
        <p>{bill.latestAction.text}</p>
        <p>{bill.latestAction.actionDate}</p>
      </section>

      <section className="bill-section">
        <h2>Bill Summary</h2>
        {summaries.length > 0 ? (
          <div className="bill-summaries">
            <div className="summary-item">
              <h3>{sortedSummaries[0].actionDesc} ({formatDate(sortedSummaries[0].actionDate)})</h3>
              <p dangerouslySetInnerHTML={{ __html: sortedSummaries[0].text }}></p>
            </div>
            {sortedSummaries.length > 1 && (
              <div className="additional-info">
                <h3>Previous Summary Dates</h3>
                <ul>
                  {sortedSummaries.slice(1).map((summary, index) => (
                    <li key={index}>
                      {formatDate(summary.actionDate)} - {summary.actionDesc}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ) : (
          <p>
            {summariesError || 
              (bill.introducedDate ? 
                `This bill was introduced on ${bill.introducedDate}. No official summary is available yet.` :
                'No summaries available in the congressional record.')}
          </p>
        )}
      </section>

      <section className="bill-section">
        <h2>Sponsors</h2>
        {bill.sponsors && bill.sponsors.length > 0 ? (
          <ul className="sponsor-list">
            {bill.sponsors.map((sponsor, index) => (
              <li key={index}>
                <Link 
                  to={`/member/${sponsor.bioguideId}`}
                  onClick={handleSponsorClick}
                >
                  {sponsor.fullName}
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          <p>No sponsors available.</p>
        )}
      </section>

      <section className="bill-section">
        <h2>Cosponsors ({cosponsors.length})</h2>
        {cosponsors.length > 0 ? (
          <>
            <ul className="cosponsor-list">
              {(showAllCosponsors ? cosponsors : cosponsors.slice(0, maxVisibleCosponsors)).map((cosponsor, index) => (
                <li key={index}>
                  <Link 
                    to={`/member/${cosponsor.bioguideId}`}
                    onClick={handleCosponsorClick}
                  >
                    {cosponsor.fullName}
                  </Link>
                  {cosponsor.sponsorshipDate && ` (${cosponsor.sponsorshipDate})`}
                </li>
              ))}
            </ul>
            {cosponsors.length > maxVisibleCosponsors && (
              <button className="toggle-btn" onClick={() => setShowAllCosponsors(!showAllCosponsors)}>
                {showAllCosponsors ? 'Show Less' : `Show All ${cosponsors.length} Cosponsors`}
              </button>
            )}
          </>
        ) : (
          <p>{cosponsorsError || 'No cosponsors available.'}</p>
        )}
      </section>

      <section className="bill-section">
        <h2>Full Bill Text</h2>
        <div className="bill-links">
          {billTextUrls.html && (
            <a href={billTextUrls.html} target="_blank" rel="noopener noreferrer">
              View Full Bill Text (HTML)
            </a>
          )}
          {billTextUrls.pdf && (
            <a href={billTextUrls.pdf} target="_blank" rel="noopener noreferrer">
              View Full Bill Text (PDF)
            </a>
          )}
          {!billTextUrls.html && !billTextUrls.pdf && (
            <p>Full bill text not available.</p>
          )}
        </div>
      </section>
    </div>
  );
};

export default BillDetail;