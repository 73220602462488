import React from "react";
import "./About.css";

const About = () => {
  return (
    <div className="about-container">
      <div className="about-header">
      </div>
      
      <div className="about-content">
        <div className="about-section">
          <h2>About Our Platform</h2>
          
          <p>
            Welcome to Long Term Capitol Management, a comprehensive resource for monitoring 
            legislative activities in Congress. We provide real-time access to bills, 
            laws, and voting records, making congressional oversight accessible to everyone.
          </p>
        </div>

        <div className="features-grid">
          <div className="feature-card">
            <h3>Law/Bill Tracking</h3>
            <p>Access and track both bills and enacted laws, complete with 
            full text and legislative history.</p>
          </div>

          <div className="feature-card">
            <h3>Member Profiles</h3>
            <p>View detailed profiles of Congress members, including their voting 
            history and sponsored legislation.</p>
          </div>

          <div className="feature-card">
            <h3>Real-Time Updates</h3>
            <p>Stay informed with the latest congressional activities and voting 
            patterns as they happen.</p>
          </div>

        </div>
        <br />
        <p>
            The name is not mispelled; it is a pun based on a hedge fund that 
            famously failed in 1998.
          </p>
          <br />
          <br />
        <div className="about-section">
          <h2>Data Transparency</h2>
          <p>
            Our platform uses official congressional data to ensure accuracy and 
            transparency in legislative tracking. We believe in making government 
            information accessible and understandable to all citizens.
          </p>
        </div>
        <br />
        <br />
        <div className="about-section contact-section">
          <h2>Contact Us</h2>
          <p>
            Have questions or feedback? We'd love to hear from you.<br />
            <a href="mailto:ltcm.contact.us@gmail.com">LTCM.contact.us@gmail.com</a>
          </p>
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default About; 