import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import './MemberDetail.css';
import LoadingSpinner from './components/LoadingSpinner';

const MemberDetail = () => {
  const apiKey = "NHdJjzbcGyGWRWiqihZ5SH6FZdChkW2fMVOBh5lT";
  const { bioguideId } = useParams();
  const [member, setMember] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sponsoredBills, setSponsoredBills] = useState([]);
  const [sponsoredBillsOffset, setSponsoredBillsOffset] = useState(0);
  const [loadingMoreBills, setLoadingMoreBills] = useState(false);
  const [showSponsoredBills, setShowSponsoredBills] = useState(false);
  const [billClickLoading, setBillClickLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMember = async () => {
      try {
        const response = await axios.get(
          `https://api.congress.gov/v3/member/${bioguideId}?format=json&api_key=${apiKey}`
        );
        setMember(response.data.member);
      } catch (error) {
        console.error("Error fetching member data:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchMember();
  }, [bioguideId]);

  const fetchSponsoredBills = async (offset = 0) => {
    try {
      setLoadingMoreBills(true);
      const response = await axios.get(
        `https://api.congress.gov/v3/member/${bioguideId}/sponsored-legislation?format=json&api_key=${apiKey}&offset=${offset}&limit=20`
      );
      
      // Log the entire response data
      console.log('Sponsored Bills API Response:', response.data);
      
      // Log just the sponsored legislation array
      console.log('Sponsored Legislation:', response.data.sponsoredLegislation);
      
      // Log details about each bill
      response.data.sponsoredLegislation.forEach(bill => {
        console.log('Bill:', bill);
        console.log('Bill Type:', bill.type);
        console.log('Bill Number:', bill.number);
        console.log('Bill Title:', bill.title);
        console.log('Latest Action:', bill.latestAction);
        console.log('---');
      });

      if (offset === 0) {
        setSponsoredBills(response.data.sponsoredLegislation);
      } else {
        setSponsoredBills(prevBills => [...prevBills, ...response.data.sponsoredLegislation]);
      }
      setSponsoredBillsOffset(offset + response.data.sponsoredLegislation.length);
      setLoadingMoreBills(false);
    } catch (error) {
      console.error("Error fetching sponsored bills:", error);
      setLoadingMoreBills(false);
    }
  };

  const loadMoreBills = () => {
    fetchSponsoredBills(sponsoredBillsOffset);
  };

  const toggleSponsoredBills = () => {
    if (!showSponsoredBills && sponsoredBills.length === 0) {
      fetchSponsoredBills(0);
    }
    setShowSponsoredBills(!showSponsoredBills);
    setSponsoredBillsOffset(0);
  };

  const calculateYearsServed = (terms) => {
    let houseYears = 0;
    let senateYears = 0;

    terms.forEach(term => {
      const years = (term.endYear || new Date().getFullYear()) - term.startYear;
      if (term.chamber === "House of Representatives") {
        houseYears += years;
      } else if (term.chamber === "Senate") {
        senateYears += years;
      }
    });

    return { houseYears, senateYears };
  };

  const handleBillClick = (bill) => {
    setBillClickLoading(true);
    navigate(`/bills/${bill.congress}/${bill.type}/${bill.number}`);
  };

  if (loading || loadingMoreBills || billClickLoading) return <LoadingSpinner />;
  if (error) return <div>Error fetching data: {error.message}</div>;
  if (!member) return <div>No member data available.</div>;

  const { houseYears, senateYears } = calculateYearsServed(member.terms);

  return (
    <div className="member-detail-container">
      <div className="member-detail-box">
        <div className="member-detail-content">
          <div className="member-detail-text">
            <h1>{member.directOrderName}</h1>
            <p><strong>BioGuide ID:</strong> {member.bioguideId}</p>
            <p><strong>Party:</strong> {member.partyHistory[0]?.partyName || "Not available"}</p>
            <p><strong>State:</strong> {member.state || "Not available"}</p>
            <p><strong>Birth Year:</strong> {member.birthYear || "Not available"}</p>
            <p><strong>Current Member:</strong> {member.currentMember ? "Yes" : "No"}</p>
            
            <h2>Years Served</h2>
            <p><strong>House of Representatives:</strong> {houseYears} years</p>
            <p><strong>Senate:</strong> {senateYears} years</p>
            
            {member.terms && member.terms.length > 0 && (
              <>
                <h2>Current Term</h2>
                <p><strong>Chamber:</strong> {member.terms[member.terms.length - 1].chamber || "Not available"}</p>
                <p><strong>Term Start:</strong> {member.terms[member.terms.length - 1].startYear || "Not available"}</p>
                <p><strong>Term End:</strong> {member.terms[member.terms.length - 1].endYear || "Ongoing"}</p>
              </>
            )}
            
            {member.addressInformation && (
              <>
                <h2>Office Information</h2>
                <p>{member.addressInformation.officeAddress}</p>
                <p>Phone: {member.addressInformation.phoneNumber}</p>
              </>
            )}
            
            {member.officialWebsiteUrl && (
              <p><strong>Official Website:</strong> <a href={member.officialWebsiteUrl} target="_blank" rel="noopener noreferrer">{member.officialWebsiteUrl}</a></p>
            )}
            
            <h2>Legislation</h2>
            <p><strong>Sponsored:</strong> {member.sponsoredLegislation.count}</p>
            <p><strong>Cosponsored:</strong> {member.cosponsoredLegislation.count}</p>
            
            <button 
              className="toggle-button"
              onClick={toggleSponsoredBills}
            >
              {showSponsoredBills ? 'Hide' : 'Show'} Sponsored Legislation
            </button>
            
            {showSponsoredBills && (
              <div className="sponsored-bills-section">
                <h3>Sponsored Legislation</h3>
                {sponsoredBills.length > 0 ? (
                  <ul className="sponsored-bills-list">
                    {sponsoredBills
                      .filter(bill => bill.title && bill.title.trim() !== '')
                      .map((bill, index) => (
                        <li 
                          key={index} 
                          className="sponsored-bill-item"
                          onClick={() => handleBillClick(bill)}
                        >
                          <div className="bill-info">
                            <strong>{bill.type} {bill.number}</strong>: {bill.title}
                          </div>
                          {bill.latestAction && bill.latestAction.actionCode === "36000" && (
                            <div className="passed-badge">
                              Passed
                            </div>
                          )}
                        </li>
                    ))}
                  </ul>
                ) : (
                  <p>Loading sponsored bills...</p>
                )}
                {showSponsoredBills && sponsoredBills.length > 0 && (
                  <button 
                    className="load-more-button"
                    onClick={loadMoreBills}
                    disabled={loadingMoreBills}
                  >
                    {loadingMoreBills ? 'Loading...' : 'Load More Bills'}
                  </button>
                )}
              </div>
            )}
          </div>
          {member.depiction?.imageUrl && (
            <div className="member-detail-image">
              <img 
                src={member.depiction.imageUrl} 
                alt={member.directOrderName} 
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MemberDetail;
