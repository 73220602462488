import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Home.css';
import LoadingSpinner from './LoadingSpinner';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const [houseCommittees, setHouseCommittees] = useState([]);
  const [senateCommittees, setSenateCommittees] = useState([]);
  const [loading, setLoading] = useState(true);
  const apiKey = 'NHdJjzbcGyGWRWiqihZ5SH6FZdChkW2fMVOBh5lT';

  const navigate = useNavigate();

  const handleCommitteeClick = (chamber, systemCode) => {
    navigate(`/committee/${chamber.toLowerCase()}/${systemCode}`);
  };

  useEffect(() => {
    const fetchCommittees = async () => {
      try {
        const [houseRes, senateRes] = await Promise.all([
          axios.get(`https://api.congress.gov/v3/committee/118/house?api_key=${apiKey}`),
          axios.get(`https://api.congress.gov/v3/committee/118/senate?api_key=${apiKey}`)
        ]);
        
        setHouseCommittees(houseRes.data.committees);
        setSenateCommittees(senateRes.data.committees);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching committees:', error);
        setLoading(false);
      }
    };

    fetchCommittees();
  }, []);

  const congressStats = {
    congress: 118,
    house: {
      total: 435,
      democrats: 213,
      republicans: 222,
      vacancies: 0,
      speaker: "Mike Johnson"
    },
    senate: {
      total: 100,
      democrats: 48,
      independents: 3,
      republicans: 49,
      vacancies: 0,
      leader: "Chuck Schumer"
    }
  };

  const senateDemocrats = congressStats.senate.democrats;
  const senateRepublicans = congressStats.senate.republicans;
  const senateIndependents = congressStats.senate.independents;
  const senateVacancies = congressStats.senate.vacancies;

  const houseDemocrats = congressStats.house.democrats;
  const houseRepublicans = congressStats.house.republicans;
  const houseIndependents = congressStats.house.independents;
  const houseVacancies = congressStats.house.vacancies;

  return (
    <div className="home-container">
      <div className="home-header">
        <h1>118th United States Congress</h1>
        <p className="congress-dates">January 3, 2023 - January 3, 2025</p>
      </div>
      
      <div className="congress-stats">
        <div className="chamber-stats">
          <h2>Senate</h2>
          <div className="seats-visual">
            <div 
              className="seats-graph" 
              style={{
                background: `conic-gradient(
                  #0015BC 0% ${senateDemocrats}%, 
                  #FF0000 ${senateDemocrats}% ${senateDemocrats + senateRepublicans}%,
                  #666 ${senateDemocrats + senateRepublicans}% 100%
                )`
              }}
            />
            <div className="total-seats">
              <p>Total Seats</p>
              <strong>100</strong>
            </div>
          </div>
          <div className="party-breakdown">
            <div className="party-count democrat">
              <p><span>Democrats</span></p>
              <strong>{senateDemocrats}</strong>
            </div>
            <div className="party-count republican">
              <p><span>Republicans</span></p>
              <strong>{senateRepublicans}</strong>
            </div>
            <div className="party-count other">
              <p><span>Other</span></p>
              <strong>{senateIndependents + senateVacancies}</strong>
            </div>
          </div>
          
          <div className="chamber-leadership">
            <div className="leader-info">
              <div className="leader democrat">
                
                <p className="leader-name">Chuck Schumer</p>
                <p className="leader-title">Majority Leader</p>
              </div>
              <div className="leader republican">
                
                <p className="leader-name">Mitch McConnell</p>
                <p className="leader-title">Minority Leader</p>
              </div>
            </div>
          </div>
        </div>

        <div className="chamber-stats">
          <h2>House</h2>
          <div className="seats-visual">
            <div 
              className="seats-graph" 
              style={{
                background: `conic-gradient(
                  #0015BC 0% ${(houseDemocrats/435)*100}%, 
                  #FF0000 ${(houseDemocrats/435)*100}% ${((houseDemocrats + houseRepublicans)/435)*100}%,
                  #666 ${((houseDemocrats + houseRepublicans)/435)*100}% 100%
                )`
              }}
            />
            <div className="total-seats">
              <p>Total Seats</p>
              <strong>435</strong>
            </div>
          </div>
          <div className="party-breakdown">
            <div className="party-count democrat">
              <p><span>Democrats</span></p>
              <strong>{houseDemocrats}</strong>
            </div>
            <div className="party-count republican">
              <p><span>Republicans</span></p>
              <strong>{houseRepublicans}</strong>
            </div>
            <div className="party-count other">
              <p><span>Other</span></p>
              <strong>{houseIndependents + houseVacancies}</strong>
            </div>
          </div>
          
          <div className="chamber-leadership">
         
            <div className="leader-info">
              <div className="leader democrat">
                
                <p className="leader-name">Hakeem Jeffries</p>
                <p className="leader-title">Minority Leader</p>
              </div>
              <div className="leader republican">
                
                <p className="leader-name">Mike Johnson</p>
                <p className="leader-title">Speaker</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="committees-section">
          <h2>Congressional Committees</h2>
          
          <div className="committees-grid">
            <div className="committee-chamber">
              <h3>House Committees</h3>
              <div className="committee-list">
                {houseCommittees.map((committee) => (
                  <div 
                    key={committee.systemCode} 
                    className="committee-card"
                    onClick={() => handleCommitteeClick('house', committee.systemCode)}
                  >
                    <h4>{committee.name}</h4>
                    <div className="committee-preview">
                      {committee.subcommittees && 
                       <span>{committee.subcommittees.length} Subcommittees</span>}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="committee-chamber">
              <h3>Senate Committees</h3>
              <div className="committee-list">
                {senateCommittees.map((committee) => (
                  <div 
                    key={committee.systemCode} 
                    className="committee-card"
                    onClick={() => handleCommitteeClick('senate', committee.systemCode)}
                  >
                    <h4>{committee.name}</h4>
                    <div className="committee-preview">
                      {committee.subcommittees && 
                       <span>{committee.subcommittees.length} Subcommittees</span>}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;